/* eslint-disable dot-notation */

import { MableError, MableErrorDetails, MableErrorProperties } from './MableError';
import { AntiClobber } from './types';

export class MableServerResponseError<Code extends string = AntiClobber<string>> extends MableError<Code> {
  public readonly body?: unknown;

  public readonly rawResponse: Response;

  public constructor(body: unknown | undefined, rawResponse: Response, opts?: Partial<MableErrorProperties<Code>>) {
    // Extract the fields from MableError's toJSON (i.e. fields that will end up in the response body) and
    // check their types.
    let code = 'Unknown';
    let message: string | undefined;
    let displayMessage: string | undefined;
    let details: MableErrorDetails[] = [];
    let data: Object | undefined;
    if (body instanceof Object) {
      code = ('code' in body ? `${body?.['code']}` : 'Unknown') as Code;
      message = 'message' in body ? `${body?.['message']}` : undefined;
      displayMessage = 'displayMessage' in body ? `${body?.['displayMessage']}` : undefined;
      data = (() => {
        const rawData: unknown = 'data' in body ? body?.['data'] : undefined;
        if (!(rawData instanceof Object)) {
          return undefined;
        }

        return rawData;
      })();
      const rawDetails: unknown = 'details' in body ? body?.['details'] : undefined;
      if (Array.isArray(rawDetails)) {
        details = (rawDetails as unknown[]).map((rawDetail): MableErrorDetails | undefined => {
          if (!(rawDetail instanceof Object)) {
            return undefined;
          }
          return {
            identifier: 'identifier' in rawDetail ? `${rawDetail['identifier']}` : '',
            displayMessage: 'displayMessage' in rawDetail ? `${rawDetail['displayMessage']}` : '',
            code: 'code' in rawDetail ? `${rawDetail['code']}` : undefined,
          };
        }).filter((d?: MableErrorDetails): d is MableErrorDetails => !!d);
      }
    }
    const correlationId = rawResponse.headers.get('Correlation-Id') || opts?.correlationId;
    super({
      code: `ServerResponse.${code}` as Code,
      message: message ?? displayMessage ?? code,
      status: rawResponse.status,
      details,
      displayMessage,
      data,
      ...(opts ?? {}),
      correlationId,
    });
    // Terrible workaround for confusing TypeScript nonsense.
    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, MableServerResponseError.prototype);

    this.body = body;
    this.rawResponse = rawResponse;

    // Omit this constructor from the stack trace of the error.
    if (Error.captureStackTrace) { // Not available in browsers
      Error.captureStackTrace(this, MableServerResponseError);
    }
  }
}
